import axios from "axios";
import { FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { AgencyVMs, CityUiVM, OtpInstanceVM } from "../shared";
import { Alert, Spinner, Tab, Tabs, Toast } from "react-bootstrap";
import { setTitle } from "../hooks/setTitle";
import { Config } from "../config";
import ControlMap from "./control/ControlMap";

const AddEditOtpInstance = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const isAddMode = !id;
  const [otpInstance, setOtpInstance] = useState(OtpInstanceVM[0]);
  const [isLoading, setLoading] = useState(false);

  if (isAddMode) {
    // do this to remove default object
    otpInstance.transitAgencies = [];
  }
  const [agencies, setAgencies] = useState(AgencyVMs);
  const [selectedAgency, setSelectedAgency] = useState(0);
  const [showToast, setShowToast] = useState(false);

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2)
      .max(50)
      .required()
      .matches(new RegExp("^[a-zA-Z_]+$"), "No special characters allowed")
      .trim(),
    timezone: yup
      .string()
      .matches(
        new RegExp("^[a-zA-Z0-9_+-]+/[a-zA-Z0-9_+-]+$"),
        "Not valid timezone"
      )
      .max(50)
      .required(),
    url: yup.string().url().max(150).required(),
    clusterUrl: yup.string().url().max(150).required(),
    otpMemory: yup.number().min(100).required(),
    graphMemory: yup.number().min(2999).required(),
    description: yup.string().max(200),
    isActive: yup.bool(),
    isOtpRestartRequired: yup.bool(),
    isGraphRebuildRequired: yup.bool(),
    isStreetGraphRebuildRequired: yup.bool(),
    osms: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          url: yup.string().url().max(150).required(),
          cropParam: yup.string().max(500),
        })
      )
      .required(),
  });

  const handleSubmit = (event: any) => {
    setLoading(true);
    if (isAddMode) {
      axios
        .post("/ui/otpinstance/create", event)
        .then((res) => {
          navigate("/otpinstance");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .put("/ui/otpinstance/update", event)
        .then((res) => {
          navigate("/otpinstance");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      axios
        .get("/ui/agency/")
        .then((res) => {
          setAgencies(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get("/ui/otpinstance/" + id)
        .then((res) => {
          setOtpInstance(res.data);
          setTitle(res.data.name);
        })
        .catch((err) => {
          console.log(err);
        });
    } else setTitle("Add Otp Instance");
  }, []);

  const getInternalUrl = (name: string) => {
    let namespace = "transit";
    if (Config.IS_DEV) namespace = "transit-dev";
    return `http://service-otp-${name
      .toLowerCase()
      .replace("_", "-")}.${namespace}.svc.cluster.local`;
  };

  const getSeelctedAgenciesComaSeparated = () => {};

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h2>{isAddMode ? "Add" : "Update"} Otp Instance</h2>
          <hr />
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            initialValues={otpInstance}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setValues,
            }) => (
              <Form noValidate onSubmit={handleSubmit} onChange={handleChange}>
                <Tabs defaultActiveKey='main' className='mb-3'>
                  <Tab eventKey='main' title='Otp'>
                    <Row>
                      <Col xs={7}>
                        {" "}
                        <Row className='mb-3'>
                          <Form.Group as={Col} xs='4'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type='text'
                              disabled={!isAddMode}
                              name='name'
                              value={values.name}
                              onChange={(e) => {
                                if (isAddMode) {
                                  setValues({
                                    ...values,
                                    url: getInternalUrl(e.target.value),
                                  });
                                }
                              }}
                              isValid={touched.name && !errors.name}
                              isInvalid={!!errors.name}
                            />
                            <Form.Text muted hidden={!isAddMode}>
                              Editing now allowed.
                            </Form.Text>
                            <Form.Control.Feedback type='invalid'>
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} xs='4'>
                            <Form.Label>Timezone</Form.Label>
                            <Form.Control
                              type='text'
                              name='timezone'
                              disabled={!isAddMode}
                              value={values.timezone}
                              onChange={handleChange}
                              isValid={touched.timezone && !errors.timezone}
                              isInvalid={!!errors.timezone}
                            />
                            <Form.Text muted hidden={!isAddMode}>
                              Editing now allowed.
                            </Form.Text>
                            <Form.Control.Feedback type='invalid'>
                              {errors.timezone}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} xs='4'>
                            <Form.Label>Internal Url</Form.Label>
                            <Form.Control
                              type='text'
                              name='url'
                              value={values.url}
                              onChange={handleChange}
                              isValid={touched.url && !errors.url}
                              isInvalid={!!errors.url}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.url}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                          <Form.Group as={Col} xs='3'>
                            <Form.Label>Otp Memory</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type='number'
                                name='otpMemory'
                                value={values.otpMemory}
                                onChange={handleChange}
                                isValid={touched.otpMemory && !errors.otpMemory}
                                isInvalid={!!errors.otpMemory}
                              />
                              <InputGroup.Text>MB</InputGroup.Text>
                            </InputGroup>
                            <Form.Text muted>
                              Any change would restart otp.
                            </Form.Text>
                            <Form.Control.Feedback type='invalid'>
                              {errors.otpMemory}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} xs='3'>
                            <Form.Label>Graph Memory</Form.Label>
                            <InputGroup>
                              <Form.Select
                                name='graphMemory'
                                value={values.graphMemory}
                                onChange={handleChange}
                                isValid={
                                  touched.graphMemory && !errors.graphMemory
                                }
                                isInvalid={!!errors.graphMemory}
                              >
                                <option value=''></option>
                                <option value='3000'>3000</option>
                                <option value='6000'>6000</option>
                                <option value='12000'>12000</option>
                                <option value='13000'>13000</option>
                                <option value='14000'>14000</option>
                                <option value='26000'>26000</option>
                                <option value='27000'>27000</option>
                                <option value='28000'>28000</option>
                                <option value='30000'>30000</option>
                                <option value='60000'>60000</option>
                              </Form.Select>
                              <InputGroup.Text>MB</InputGroup.Text>
                            </InputGroup>
                            <Form.Control.Feedback type='invalid'>
                              {errors.graphMemory}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} xs='6'>
                            <Form.Label>Cluster Url</Form.Label>
                            <Form.Control
                              type='text'
                              name='clusterUrl'
                              value={values.clusterUrl}
                              onChange={handleChange}
                              isValid={touched.clusterUrl && !errors.clusterUrl}
                              isInvalid={!!errors.clusterUrl}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.clusterUrl}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className='mb-6'>
                          <Form.Group as={Col} xs='12'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as='textarea'
                              rows={3}
                              type='text'
                              name='description'
                              value={values.description}
                              onChange={handleChange}
                              isValid={
                                touched.description && !errors.description
                              }
                              isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.description}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>                        
                        <Row className={isAddMode ? "hidden" : "mb-6"}>
                          <Form.Group as={Col} xs='6'>
                            <Form.Check
                              type='switch'
                              name='isActive'
                              checked={values.isActive}
                              onChange={handleChange}
                              label='Active'
                            />
                            <Alert key='info' variant='warning'>
                              If inactive, otp won't start inside pod & no jobs will run.
                            </Alert>
                            <Form.Check
                              type='switch'
                              name='isOtpRestartRequired'
                              checked={values.isOtpRestartRequired}
                              onChange={handleChange}
                              label='Otp Restart'
                            />
                            <Alert key='info' variant='info'>
                              Schedules an otp to restart soon.
                            </Alert>
                            <Form.Check
                              type='switch'
                              name='isProductionReady'
                              checked={values.isProductionReady}
                              onChange={handleChange}
                              label='Production Ready'
                            />
                            <Alert key='info' variant='success'>
                              Turn it ON, when this instance is ready to serve
                              request. This makes it available to users.
                            </Alert>
                          </Form.Group>
                          <Form.Group as={Col} xs='6'>
                            <Form.Check
                              type='switch'
                              name='isGraphRebuildRequired'
                              checked={values.isGraphRebuildRequired}
                              onChange={handleChange}
                              label='Graph Rebuild'
                            />
                            <Alert key='info' variant='warning'>
                              Schedules an otp graph to be rebuilt soon.
                            </Alert>
                            <Form.Check
                              type='switch'
                              name='isStreetGraphRebuildRequired'
                              checked={values.isStreetGraphRebuildRequired}
                              onChange={handleChange}
                              label='Street Graph Rebuild'
                            />
                            <Alert key='info' variant='warning'>
                              Schedules an otp street graph to be rebuilt soon.
                              <br />
                              It also schedules graph to be build soon.
                            </Alert>
                            <Form.Check
                              type='switch'
                              name='isOsmRebuildRequired'
                              checked={values.isOsmRebuildRequired}
                              onChange={handleChange}
                              label='Osm Build'
                            />
                            <Alert key='info' variant='warning'>
                              Schedules an osm to be rebuilt soon.
                              <br />
                              It also schedules street graph, graph to be build
                              soon.
                            </Alert>
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col xs={5}>
                        <h6>Agencies</h6>
                        <hr />

                        <div className={isAddMode ? "" : "hidden"}>
                          You can not add agencies while creating otp instance
                        </div>

                        <FieldArray
                          name='transitAgencies'
                          render={(arrayHelpers: any) => (
                            <>
                              <Row className={isAddMode ? "hidden" : "mb-3"}>
                                <Form.Group as={Col} xs='6'>
                                  <InputGroup className='mb-3'>
                                    <Form.Select
                                      onChange={(e) => {
                                        setSelectedAgency(
                                          Number(e.target.value)
                                        );
                                      }}
                                      value={selectedAgency}
                                    >
                                      <option disabled value='0'>
                                        None
                                      </option>
                                      {agencies
                                        .filter(
                                          (r) =>
                                            r.agencyTimezone ==
                                              values.timezone && r.isActive
                                        )
                                        .sort((a, b) =>
                                          a.agencyName < b.agencyName ? -1 : 1
                                        )
                                        .map((item, i) => {
                                          return (
                                            <>
                                              <option value={item.id}>
                                                {item.agencyName} - {item.state}
                                                , {item.country}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Form.Select>
                                    <Button
                                      variant='outline-secondary'
                                      onClick={(e) => {
                                        if (selectedAgency == 0) return;

                                        if (
                                          values.transitAgencies.filter(
                                            (r) => r.id == selectedAgency
                                          ).length > 0
                                        ) {
                                          setShowToast(true);
                                          return;
                                        }
                                        let agency = agencies.filter(
                                          (r) => r.id == selectedAgency
                                        )[0];

                                        arrayHelpers.push({
                                          id: agency.id,
                                          name: agency.agencyName,
                                          timezone: agency.agencyTimezone,
                                        });
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </InputGroup>
                                  <Form.Text muted>
                                    Showing active agencies which timezone
                                    matches otp timezone.
                                  </Form.Text>
                                  <Toast
                                    onClose={() => setShowToast(false)}
                                    show={showToast}
                                    delay={5000}
                                    autohide
                                  >
                                    <Toast.Body className='text-danger'>
                                      Oops, agency already exist.
                                    </Toast.Body>
                                  </Toast>
                                </Form.Group>
                                <Form.Group as={Col} xs='6'>
                                  <ul className='list-group'>
                                    {values.transitAgencies.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            <li
                                              className='list-group-item'
                                              key={index}
                                            >
                                              {item.name}
                                              <a
                                                href='#'
                                                className='pull-right text-danger'
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                X
                                              </a>
                                            </li>
                                          </>
                                        );
                                      }
                                    )}
                                  </ul>
                                </Form.Group>
                              </Row>
                            </>
                          )}
                        />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey='osm' title='Osm Info'>
                    <FieldArray
                      name='osms'
                      render={(arrayHelpers: any) => (
                        <>
                          <Row className='mb-3'>
                            <Col xs={12}>
                              <Button
                                onClick={() =>
                                  arrayHelpers.push(OtpInstanceVM[0].osms[0])
                                }
                              >
                                <i className='fa-regular fa-plus'></i> Add
                              </Button>
                            </Col>
                          </Row>

                          {values.osms.map((item, index) => {
                            return (
                              <>
                                <div key={index}>
                                  <Row className='mb-3'>
                                    <Form.Group as={Col} xs='6'>
                                      <Form.Label>Map Url</Form.Label>
                                      <InputGroup>
                                        <Button
                                          variant='danger'
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <i className='fa-solid fa-trash'></i>
                                        </Button>
                                        <Form.Control
                                          type='text'
                                          name={`osms.${index}.url`}
                                          value={item.url}
                                          onChange={handleChange}
                                        />
                                      </InputGroup>
                                      <Form.Text muted>
                                        http://download.geofabrik.de/
                                      </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} xs='6'>
                                      <Form.Label>
                                        Crop Param (Semi-colon seprated)
                                      </Form.Label>
                                      <Form.Control
                                        as='textarea'
                                        rows={3}
                                        type='text'
                                        name={`osms.${index}.cropParam`}
                                        value={item.cropParam}
                                        onChange={handleChange}
                                      />
                                      <Form.Text muted>
                                        https://boundingbox.klokantech.com/
                                        <br />
                                        Select CSV from below
                                      </Form.Text>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    />

                    <div className='text-primary'>
                      Any chnage will create new osm build job.
                    </div>
                    <br />
                  </Tab>
                  <Tab eventKey='map' title='Map'>
                    {agencies.length > 0 && agencies[0].agencyId !== "" ? (
                      <ControlMap
                        agencies={otpInstance.transitAgencies
                          .map((a) => a.agencyId)
                          .join(",")}
                        polygons={otpInstance.osms.map((r) => r.cropParam)}
                      />
                    ) : (
                      ""
                    )}
                    <br />
                  </Tab>
                </Tabs>

                <Button type='submit' className='mr-3' disabled={!isValid}>
                  {isLoading ? (
                    <Spinner animation='border' variant='light' size='sm' />
                  ) : (
                    <>
                      <i className='fa-regular fa-floppy-disk'></i>&nbsp;&nbsp;
                      {isAddMode ? "Save" : "Update"}
                    </>
                  )}
                </Button>
                <Button href='/otpinstance' variant='light'>
                  <i className='fa-solid fa-xmark'></i> &nbsp; Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddEditOtpInstance;
